export const MANUAL = ['Lets get you started, shall we?',
  'Simply enter the name of the dish you have in mind...',
  'Or add ingredients, if you just want to cook with stuff you\'ve got around',
  'Tutorial success. Cook away!'
];
export const MANUAL_EMPTY = [
  '',
  '',
  '',
  ''
];
